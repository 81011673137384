import React from 'react';
import { IAppForAppList } from '../../../models/interfaces';
import ListOfApps from '../ListOfApps';
import { fetchAppPermissionsAsync, getProductTier } from '../../../utils/apiCalls';

interface IAppSectionProps {
  navbarData: any;
}

export default function AppSection(props: IAppSectionProps) {
  const [appList, setAppList] = React.useState<IAppForAppList[] | null>(null);
  const [isPgmTierFetchDone, setIsPgmTierFetchDone] = React.useState(false);

  React.useEffect(() => {
    if (props.navbarData && props.navbarData.loggedIn) {
      getApps();
    }
  }, [props.navbarData?.loggedIn]);

  React.useEffect(() => {
    if (appList && appList.length > 0 && !isPgmTierFetchDone) {
      getProductTierForPgmApp();
    }
  }, [appList]);

  async function getApps() {
    try {
      const data = await fetchAppPermissionsAsync(props.navbarData.jtToken);
      // FIXME: Remove hardcoded appId filtering
      const filteredApps = data.filter((app: IAppForAppList) => app.appId !== 4 && app.appId !== 11 && app.appId !== 34);
      setAppList(filteredApps);
    } catch (err) {
      console.error('🌏 applist error', err);
    }
  }

  // PCATP-4361 function to fetch product tier for PGM app using company and division details.
  async function getProductTierForPgmApp() {
  try {
    const pgmAppData = (appList ?? []).find((app) => app.appId === 2);

    // return if no pgm app
    if (!pgmAppData) return;

    // Get product tier
    let divisionId = props.navbarData.currentDivision.id;
    const pgmDivision = props.navbarData.divisions.filter((item:any) => 
      item.name.toLowerCase().includes('programmatic'));

    if (pgmDivision && pgmDivision.length > 0) {
      divisionId = pgmDivision[0].id;
    }

    const data = await getProductTier({
      token: props.navbarData.jtToken,
      company_id: props.navbarData.company.companyId,
      division_id: divisionId,
    });
    
    setIsPgmTierFetchDone(true);
    if (data?.status === "success") {
      // Copy the app list and then update product tier of pgm app.
      const updatedAppList = (appList ?? []).map((app) => 
        app.appId === 2 ? { ...app, productTier: data.data?.product_tier } : app
      );

      // Update PGM app product tier in app list
      setAppList(updatedAppList);
    } else {
      console.info('Fetching pgm product tier not succesful');
    }
  } catch (err) {
    console.error('Pgm product tier error', err);
  }
}


  return (
    <div className={`col-12 ${appList ? '' : 'd-flex justify-content-center align-items-center'}`}>
      {appList && <ListOfApps appList={appList} userId={props.navbarData.user_id} navbarData={props.navbarData} />}
      {!appList && (
        <div className="spinner-border text-primary h-6 w-6" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
}
