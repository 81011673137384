export const urlEnum = {
    CANDIDATE_MANAGER_API: process.env.NEXT_PUBLIC_CANDIDATE_API,
    CANDIDATE_MANAGER_UI: process.env.NEXT_PUBLIC_APP_LINK_CANDIDATE_MANAGER,
    PGM_API: process.env.NEXT_PUBLIC_PGM_API,
}

export const supportedPgmPlusTier: string[] = [
    "Programmatic+",
    "Programmatic+ Post Pay",
];

export const supportedPgm360Tier: string[] = [
    "Programmatic 360",
    "Programmatic 360 Post Pay",
];